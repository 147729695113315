import React from 'react';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorView from 'components/assets/AssetGalleryDialog/components/content/asset-editor-view';
import File from 'components/data/Files';
import AssetGalleryDialogSideBar from '../content/sidebar';
import AssetGalleryDialogState from '../../interfaces/AssetGalleryDialogState';
import AssetAudioTrimmerWrapper from './asset-audio-trimmer';
import AssetGalleryDialogProps from '../../interfaces/AssetGalleryDialogProps';

interface AssetGalleryDialogContentState {
    selectedContentView: AssetGalleryDialogState['selectedContentView'];
}

interface Props {
    selectorsProps?: AssetGalleryDialogProps['selectorsProps'];
    onClose: () => void; // Closes the dialog.
    onMutation: (data: unknown, dataType?: string) => void; // Saves data to the store.
    onChangeAcceptedList?: (acceptedList: File[]) => void; // Callback function the return the acceptedList. You can for example use this to check how many assets are going to go through the upload process
    onBack: () => void;
}
/**
 * This component is responsible for rendering the content of the AssetGalleryDialog.
 * This component will switch between the sidebar and its content, asset editor and audio trimmer.
 */
const AssetGalleryDialogContent: React.FC<Props> = ({ onClose, onMutation, onChangeAcceptedList, onBack, selectorsProps }) => {
    const { selectedContentView } = useComponentStore<AssetGalleryDialogContentState>('AssetGalleryDialog', {
        fields: { selectedContentView: 'selectedContentView' }
    });

    return (
        <>
            {selectedContentView === 'sidebar' && (
                <AssetGalleryDialogSideBar
                    onClose={onClose}
                    onMutation={onMutation}
                    onChangeAcceptedList={onChangeAcceptedList}
                    selectorsProps={selectorsProps}
                />
            )}

            {selectedContentView === 'asset-editor' && <AssetEditorView onClose={onClose} onMutation={onMutation} />}

            {selectedContentView === 'audio-trimmer' && <AssetAudioTrimmerWrapper onClose={onClose} onMutation={onMutation} onBack={onBack} />}
        </>
    );
};

export default AssetGalleryDialogContent;
