import React, { useEffect, useState } from 'react';
import ImageFileService from 'services/image-file/image.service';
import ImageModifierService from 'services/image-modifier/image-modifier.service';
import Tooltip from 'components/ui-components-v2/Tooltip';
import Button from 'components/ui-components-v2/Button';
import AssetEditor from 'components/assets/AssetEditor';
import Translation from 'components/data/Translation';
import useComponentStore from 'components/data/ComponentStore/hooks/useComponentStore';
import AssetEditorState from 'components/assets/AssetEditor/interfaces/AssetEditorState';
import ComponentStoreHelpers from 'components/data/ComponentStore';
import AssetEditorHelper from 'components/assets/AssetEditor/helpers/asset-editor-helper';
import FileHelper from 'components/data/Files';
import OverlayLoadingIndicatorWrapper from 'components/assets/AssetEditor/components/OverlayLoadingIndicator';
import { ImageCropperState, OutpaintState, VideoCropperState } from 'components/assets/AssetGalleryCropper/interfaces/asset-cropper-state';
import AssetFlipperState from 'components/assets/AssetFlipper/interfaces/AssetFlipperState';
import ImageCompressorState from 'components/assets/AssetEditorControllers/components/ImageCompressor/interfaces/image-compressor-state';
import AssetBackgroundRemoverState from 'components/assets/AssetEditorControllers/components/AssetBackgroundRemover/interfaces/AssetBackgroundRemoverState';
import AssetGalleryDialogState from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryDialogState';
import AssetGalleryStore from 'components/assets/AssetGalleryDialog/helpers/asset-gallery-store';
import SnackbarUtils from 'components/ui-base/SnackbarUtils';
import AssetGalleryData from 'components/assets/AssetGalleryDialog/interfaces/AssetGalleryData';
import { DEFAULT_COMPRESSION_LEVEL } from 'components/assets/AssetEditorControllers/components/ImageCompressor/components/image-compressor-slider-row';
import AssetHelper from 'helpers/asset.helper';

import './styles/main.scss';

interface AssetGalleryDialogStateProps {
    value: AssetGalleryDialogState['value'];
    useCropper: AssetGalleryDialogState['conditionProps']['useCropper'];
    useCompressor: AssetGalleryDialogState['conditionProps']['useCompressor'];
    canSkipCompressor: AssetGalleryDialogState['conditionProps']['canSkipCompressor'];
    canSkipCropper: AssetGalleryDialogState['conditionProps']['canSkipCropper'];
    outputWidth: AssetGalleryDialogState['data']['assetData']['outputWidth'];
    outputHeight: AssetGalleryDialogState['data']['assetData']['outputHeight'];
    canReuseImage: AssetGalleryDialogState['conditionProps']['canReuseImage'];
    useCustomCompressor: AssetGalleryDialogState['conditionProps']['useCustomCompressor'];
}
interface AssetEditorViewState {
    originalAssetSrc: string;
    modifiedAssetSrc: string;
    componentKey: AssetEditorState['componentKey'];
    croppedModifiedAssetSrc: string;
    imageCropperState: ImageCropperState;
    outpaintState: OutpaintState;
    assetFlipperState: AssetFlipperState;
    imageCompressorState: ImageCompressorState;
    backgroundRemoverState: AssetBackgroundRemoverState;
    loading: boolean;
    videoCropperStateCropData: VideoCropperState['cropData'];
    videoCropperStateMutationData: VideoCropperState['mutationData'];
    assetData: AssetEditorState['assetData'];
    videoDuration?: number;
    isAssetModified: AssetEditorState['isAssetModified'];
}

interface Props {
    onClose: () => void; // Closes the dialog.
    onMutation: (data: any, dataType?: string) => void; // Saves data to the store.
}

/**
 * Renders the asset editor view for the asset gallery dialog.
 */
const AssetEditorView: React.FC<Props> = ({ onClose, onMutation }) => {
    const [warningMessage, setWarningMessage] = useState('');
    const [isActionButtonDisabled, setIsActionButtonDisabled] = useState(false);
    const [showNextButton, setShowNextButton] = useState<boolean | undefined>(false);
    const [isAssetEditorViewLoading, setIsAssetEditorViewLoading] = useState(false);

    const { value, useCropper, canSkipCompressor, useCompressor, canSkipCropper, outputWidth, outputHeight, canReuseImage, useCustomCompressor } =
        useComponentStore<AssetGalleryDialogStateProps>('AssetGalleryDialog', {
            fields: {
                value: 'value',
                useCropper: 'conditionProps.useCropper',
                useCompressor: 'conditionProps.useCompressor',
                canSkipCropper: 'conditionProps.canSkipCropper',
                canSkipCompressor: 'conditionProps.canSkipCompressor',
                outputHeight: 'data.assetData.outputHeight',
                outputWidth: 'data.assetData.outputWidth',
                canReuseImage: 'conditionProps.canReuseImage',
                useCustomCompressor: 'conditionProps.useCustomCompressor'
            }
        });

    const {
        originalAssetSrc,
        modifiedAssetSrc,
        componentKey,
        croppedModifiedAssetSrc,
        imageCropperState,
        outpaintState,
        assetFlipperState,
        imageCompressorState,
        backgroundRemoverState,
        loading,
        videoCropperStateCropData,
        videoCropperStateMutationData,
        assetData,
        videoDuration,
        isAssetModified
    } = useComponentStore<AssetEditorViewState>('AssetEditor', {
        fields: {
            originalAssetSrc: 'originalAssetSrc',
            modifiedAssetSrc: 'modifiedAssetSrc',
            componentKey: 'componentKey',
            croppedModifiedAssetSrc: 'croppedModifiedAssetSrc',
            imageCropperState: 'imageCropperState',
            outpaintState: 'outpaintState',
            assetFlipperState: 'assetFlipperState',
            videoCropperStateCropData: 'videoCropperState.cropData',
            videoCropperStateMutationData: 'videoCropperState.mutationData',
            imageCompressorState: 'imageCompressorState',
            backgroundRemoverState: 'backgroundRemoverState',
            loading: 'loading',
            assetData: 'assetData',
            videoDuration: 'videoCropperState.duration',
            isAssetModified: 'isAssetModified'
        }
    });

    const fileType = AssetHelper.getFileType(value.extension);

    /**
     * Skip the cropping of the video and upload the original file
     **/
    const handleSkip = (type) => {
        let updatedValue = value;

        if (type === 'video-cropper') {
            updatedValue = { ...value, videoDuration }; // Add video duration to the value object.

            // Save the skipped asset and close dialog
            if (canReuseImage) {
                AssetGalleryStore.storeAsset(updatedValue);
            }
        }

        onMutation(updatedValue);
        onClose();
    };

    /**
     * Set the newly cropped asset and close dialog
     */
    const handleCroppedAsset = (item) => {
        delete item.width;
        delete item.height;

        const updatedValue = { ...value, ...item, videoDuration }; // Add video duration and item to the value object.

        ComponentStoreHelpers.setModel<AssetGalleryDialogState, 'value'>('AssetGalleryDialog', 'value', updatedValue);

        // Save cropped asset and close dialog
        if (canReuseImage) {
            AssetGalleryStore.storeAsset(updatedValue);
        }

        onMutation(updatedValue);
        onClose();
    };

    const checkDoneButtonState = () => {
        // Show crop warning message if the image is not cropped and useCropper is true and canSkipCropper is false.
        // For the old templates, sometimes the "useCropper" is not set but the outputWidth or outputHeight is set, so we still need to show the crop warning message because it still a must to crop.
        if ((useCropper && !canSkipCropper) || ((outputWidth || outputHeight) && !canSkipCropper)) {
            if ((fileType !== 'video' && !imageCropperState?.cropData) || (fileType === 'video' && !videoCropperStateCropData)) {
                setWarningMessage(Translation.get('assetGalleryDialog.assetEditor.cropWarning', 'content-space'));
                setIsActionButtonDisabled(true);
                return;
            }
        }

        // Show compress warning message if the image size is bigger than the max size and the image is not compressed and useCompressor is true and canSkipCompressor is false.
        if (!imageCompressorState?.isCompressed && componentKey === 'imageCompressor') {
            setWarningMessage(Translation.get('assetGalleryDialog.assetEditor.exportWarning', 'content-space'));
            setIsActionButtonDisabled(true);
            return;
        }

        setWarningMessage(''); // Clear warning message.
        setIsActionButtonDisabled((componentKey !== 'imageCompressor' && componentKey !== 'previewAsset') || isAssetEditorViewLoading || loading);
    };

    /** Reverts the modified asset source to the original asset source and make sure that the preview asset is shown. */
    const revertToOriginal = () => {
        // Reset video value object.
        if (value?.originalVideo) {
            value.url = value.originalVideo;
            value.crop = undefined;
            value.inputStartTime = undefined;
            value.inputEndTime = undefined;
        }

        // Make sure the mutation object is also rested.
        const onMutationObject = {
            inputStartTime: undefined,
            inputEndTime: undefined,
            fileType: 'video',
            originalVideo: value.originalVideo,
            url: value.originalVideo,
            isCropped: false,
            cropData: undefined
        };

        ComponentStoreHelpers.setMultiModels('AssetEditor', [
            ['modifiedAssetSrc', ''],
            ['croppedModifiedAssetSrc', ''],
            ['imageCropperState', undefined],
            ['outpaintState', undefined],
            ['videoCropperState', { cropData: undefined, croppedModifiedAssetSrc: '', mutationData: onMutationObject }],
            ['assetFlipperState', { rotationAngle: undefined, scaleX: 1, scaleY: 1 }],
            ['imageCompressorState', { compressionLevel: DEFAULT_COMPRESSION_LEVEL, isCompressed: false }],
            ['backgroundRemoverState', { isBackgroundRemoved: false }]
        ]);

        // Reset video cropper state.
        ComponentStoreHelpers.setData<VideoCropperState>('VideoCropper', {
            selectedAspectRatio: '0',
            croppedModifiedAssetSrc: '',
            trimData: undefined,
            cropData: undefined,
            mutationData: undefined
        });

        if (componentKey !== 'previewAsset') {
            ComponentStoreHelpers.setModel('AssetEditor', 'componentKey', 'previewAsset');
        }
    };

    /** Only compress if useCompressor is enabled and useCustomCompressor is false,
     *  this is needed because when useCustomCompressor is false, the compressor review page will be skipped and the compression needs be done automatically.
     */
    const handleCompression = async (imageUrl: string) => {
        try {
            if (useCompressor && !useCustomCompressor) {
                return await ImageModifierService.compressImage(imageUrl);
            }

            return imageUrl;
        } catch (error) {
            return imageUrl;
        }
    };

    /**
     * Updates the asset gallery data size.
     * @param value The asset gallery data to be updated.
     * @param assetUrl The asset url to get the size from.
     * @returns The updated asset gallery data with the size and human size.
     */
    const updateAssetGalleryDataSize = async (value: AssetGalleryData, assetUrl: string) => {
        const size = await ImageFileService.getFileSizeInKBFromUrl(assetUrl);
        const sizeInBytes = ImageFileService.convertKiloBytesToBytes(size);
        const humanSize = FileHelper.humanReadableSize(sizeInBytes);

        return { ...value, humanSize: humanSize ?? value.humanSize, size: size ?? value.size };
    };

    const onSaveClick = async () => {
        setIsAssetEditorViewLoading(true); // Set loading to true to show the loading spinner around the asset editor view content.

        // Set the original width and height to the assetData object to prevent issues with the old templates from Template Designer.
        value.assetData = { width: value.width, height: value.height };

        // It's important to delete the width and height from the value because it causes issues with the old templates from Template Designer.
        delete value.width;
        delete value.height;

        if (fileType === 'video') {
            const { humanSize, size } = await updateAssetGalleryDataSize(value, value.url);
            value.humanSize = humanSize;
            value.size = size;

            if (!videoCropperStateMutationData) {
                handleSkip('video-cropper');
            } else {
                handleCroppedAsset(videoCropperStateMutationData);
            }
        } else {
            const mainSrc = AssetEditorHelper.getAssetUrl(originalAssetSrc, modifiedAssetSrc, croppedModifiedAssetSrc); // Src to be saved.
            const base64Assets = [mainSrc, modifiedAssetSrc]; // Base64 assets to be converted to urls.
            const originalUrl = value?.originalImage || value?.url;
            // eslint-disable-next-line prefer-const
            let [mainUrl, modifiedAssetUrl] = isAssetModified ? await ImageFileService.convertBase64AssetsToUrls(base64Assets) : [originalUrl]; // Convert base64 assets to urls.

            if (!mainUrl) {
                SnackbarUtils.error(Translation.get('feedback.errors.oops', 'common'));
                return;
            }

            mainUrl = await handleCompression(mainUrl);
            const updatedValue = await updateAssetGalleryDataSize(value, mainUrl);

            const assetGalleryDataCopy: AssetGalleryData = {
                ...updatedValue,
                modifiedAsset: modifiedAssetUrl,
                originalImage: value.originalImage ? value.originalImage : value.url,
                assetFlipperState: assetFlipperState,
                crop: imageCropperState?.cropData ? { ...imageCropperState?.cropData } : imageCropperState?.cropData,
                outpaintState: outpaintState,
                openAssetEditor: true, // Set openAssetEditor state to true to open the asset editor dialog.
                imageCompressorState: { ...imageCompressorState, isCompressed: false }, // isCompressed should always be false when saving the image.
                backgroundRemoverState: { ...backgroundRemoverState },
                assetData: assetData // Save original width and height.
            };

            assetGalleryDataCopy.url = mainUrl; // Set the modified asset url.

            handleCroppedAsset(assetGalleryDataCopy);
        }

        setIsAssetEditorViewLoading(false); // Set loading to false to hide the loading spinner around the asset editor view content.
    };

    /** Goes to image compressor view. */
    const onNextStepClick = () => {
        ComponentStoreHelpers.setModel('AssetEditor', 'componentKey', 'imageCompressor');
    };

    /** Determines if the next button should be shown or not.*/
    const handleShowNextButton = () => {
        const showNextButton = useCustomCompressor === true && useCompressor && componentKey === 'previewAsset' && fileType !== 'video';
        setShowNextButton(showNextButton);
    };

    /**
     * Determines whether to show the revert button based on whether the asset source or crop data has been modified.
     */
    const handleShowRevertButton = () => {
        const assetEditor: AssetEditorState | undefined = ComponentStoreHelpers.get('AssetEditor');

        if (!assetEditor) return; // Return if the asset editor is not set.

        if (modifiedAssetSrc || imageCropperState?.cropData || videoCropperStateCropData || imageCompressorState?.isCompressed) {
            ComponentStoreHelpers.setModel('AssetEditor', 'isAssetModified', true);
            return;
        }
        ComponentStoreHelpers.setModel('AssetEditor', 'isAssetModified', false);
    };

    useEffect(() => {
        handleShowNextButton();
    }, [componentKey, useCompressor]);

    useEffect(() => {
        checkDoneButtonState();
    }, [imageCropperState?.cropData, useCropper, useCompressor, componentKey, imageCompressorState, loading, isAssetEditorViewLoading]);

    useEffect(() => {
        handleShowRevertButton();
    }, [modifiedAssetSrc, imageCropperState?.cropData, videoCropperStateCropData, imageCompressorState?.isCompressed]);

    return (
        <div className="asset-editor-view">
            <div className="asset-editor-view__editor" data-mui-color-scheme="dark">
                <OverlayLoadingIndicatorWrapper
                    text={Translation.get('assetGalleryDialog.assetEditor.loading', 'content-space')}
                    isLoading={isAssetEditorViewLoading}>
                    <AssetEditor />
                </OverlayLoadingIndicatorWrapper>
            </div>
            <div className="asset-editor-view__footer">
                <div className="asset-editor-view__footer__actions">
                    <div className="asset-editor-view__footer__actions__revert">
                        {isAssetModified && (
                            <Button onClick={revertToOriginal} variant="text" color="primary">
                                {Translation.get('assetGalleryDialog.assetEditor.restoreOriginal', 'content-space')}
                            </Button>
                        )}
                    </div>
                    {canSkipCompressor && componentKey === 'imageCompressor' && !imageCompressorState?.isCompressed && (
                        <Button
                            data-cy="assetEditor-skip&Insert-button"
                            className={'asset-editor-view__footer__actions__skipAndInsert'}
                            disabled={loading || isAssetEditorViewLoading}
                            onClick={onSaveClick}
                            variant="outlined"
                            color="primary">
                            {Translation.get('actions.skip&insert', 'common')}
                        </Button>
                    )}

                    <div className="asset-editor-view__footer__actions__save">
                        <Tooltip title={warningMessage}>
                            <div>
                                {showNextButton ? (
                                    <Button
                                        data-cy="assetEditor-goToNextStep-button"
                                        disabled={isActionButtonDisabled}
                                        onClick={() => {
                                            onNextStepClick();
                                        }}
                                        variant="contained"
                                        color="primary">
                                        {Translation.get('actions.nextStep', 'common')}
                                    </Button>
                                ) : (
                                    <Button
                                        data-cy="assetEditor-finish-button"
                                        disabled={isActionButtonDisabled}
                                        onClick={() => {
                                            onSaveClick();
                                        }}
                                        variant="contained"
                                        color="primary">
                                        {Translation.get('actions.insert', 'common')}
                                    </Button>
                                )}
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AssetEditorView;
